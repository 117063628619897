<template>
    <HtTabStepperModel
        :id="id"
        :tabs="tabs"
        :item-fields="itemFields"
        :item-relations="itemRelations"
        :model-name="'CompanyProgram'"
        :collection-name="'CompanyProgramCollection'"
        @on-close-stepper="$emit('on-close-stepper')"
    />
</template>

<script>
import HtTabStepperModel from '@/layouts/HtTabStepperModel.vue';
import ProgramFormSettings from './ProgramFormSettings.vue';
import ProgramFormProgram from './ProgramFormProgram.vue';
import ProgramFormEmail from './ProgramFormEmail.vue';
import ProgramFormTarget from './ProgramFormTarget.vue';

export default {
    name: 'ProgramForm',
    components: {
        HtTabStepperModel,
    },

    props: {
        id: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            itemFields: [
                'id',
                'company_program_id',
                'language_key',
                'ht_program_type_id',
                'company_chatbot_id',
                'name',
                'description',
                'switch_enabled',
                'switch_offset',
                'switch_type',
                'following_program_id',
                'is_self_enrollable',
            ],
            itemRelations: {
                notifications: (query) => {
                    query
                        .select([
                            'id',
                            'company_id',
                            'template_slug',
                            'variables',
                            'sender_user_id',
                            'company_file_id',
                            'company_program_id',
                            'is_enabled',
                            'show_enabled',
                        ])
                        .with({
                            templates: (query) => {
                                query
                                    .select(['id', 'is_enabled', 'canal_type'])
                                    .with({
                                        locales: (query) => {
                                            query.select([
                                                'id',
                                                'language_key',
                                                'subject',
                                                'content',
                                            ]);
                                        },
                                    });
                            },
                            senderUser: (query) => {
                                query.select([
                                    'id',
                                    'firstname',
                                    'lastname',
                                    'image',
                                ]);
                            },
                            recipients: (query) => {
                                query
                                    .select([
                                        'id',
                                        'company_group_id',
                                        'company_role_id',
                                        'company_user_id',
                                    ])
                                    .with({
                                        companyRole: (query) => {
                                            query.select([
                                                'id',
                                                'name',
                                                'alias',
                                                'is_heyteam',
                                                'deleted_at',
                                            ]);
                                        },
                                        companyUser: (query) => {
                                            query.select([
                                                'id',
                                                'firstname',
                                                'lastname',
                                                'email',
                                                'image',
                                                'professional_email',
                                            ]);
                                        },
                                        companyGroup: (query) => {
                                            query.select([
                                                'id',
                                                'alias',
                                                'name',
                                            ]);
                                        },
                                    });
                            },
                            attachment: (query) => {
                                query.select(['id', 'original_name', 'link']);
                            },
                        });
                },
                locales: (query) => {
                    query.select([
                        'id',
                        'company_program_id',
                        'language_key',
                        'name',
                        'description',
                    ]);
                },
                keyDates: (query) => {
                    query.select(['id', 'ht_program_key_date_id', 'is_main']);
                    query.with({
                        locales: (query) => {
                            query
                                .select(['id', 'name', 'language_key'])
                                .where([
                                    [
                                        'language_key',
                                        '=',
                                        this.shared.session.companyUser
                                            .company_language.key,
                                    ],
                                ]);
                        },
                    });
                },
                entities: (query) => {
                    query.select([
                        'id',
                        'company_program_id',
                        'company_entity_id',
                        'is_deletable',
                    ]);
                    query.with({
                        entity: (query) => {
                            query.select(['id', 'name', 'slug', 'is_heyteam']);
                        },
                    });
                },
                roleProgram: (query) => {
                    query.select(['id', 'company_program_id', 'company_role_id']);
                    query.with({
                        companyRole: (query) => {
                            query.select([
                                'id',
                                'name',
                                'alias',
                                'is_heyteam',
                                'is_assignable_program',
                                'is_assignable_resource',
                            ]);
                        },
                    });
                },
            },
            tabs: [
                {
                    ref: 'settings',
                    title: this.translate('Settings'),
                    component: ProgramFormSettings,
                    noBody: false,
                },
                {
                    ref: 'target',
                    title: this.translate('Target'),
                    component: ProgramFormTarget,
                    disabled: !this.id,
                },
                {
                    ref: 'program',
                    title: this.translate('Program'),
                    component: ProgramFormProgram,
                    disabled: !this.id,
                    noBody: true,
                },
                {
                    ref: 'email',
                    title: this.translate('Email'),
                    disabled: !this.id,
                    component: ProgramFormEmail,
                },
            ],
        };
    },
};
</script>
